import Vue from "vue";

export default {
    version: "4.2",
    BASE_URL: 'https://cloud.gph.ro/api/index.php/',


    GlobalDatePickerOptions: {
        shortcuts: [{
            text: 'Azi',
            onClick(picker) {
                picker.$emit('pick', new Date());
            }
        }, 
        {
            text: 'Ieri',
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24);
                picker.$emit('pick', date);
            }
        }, 
        {
            text: '-1 saptamana',
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit('pick', date);
            }
        }, 
        {
            text: '-30 zile',
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit('pick', date);
            }
        }, 
        {
            text: '-90 zile',
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit('pick', date);
            }
        }, 
        {
            text: '-365 zile',
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 365);
                picker.$emit('pick', date);
            }
        }
    
        , 
        {
            text: '+1 saptamana',
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
                picker.$emit('pick', date);
            }
        }, 
        {
            text: '+30 zile',
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
                picker.$emit('pick', date);
            }
        }, 
        {
            text: '+90 zile',
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() + 3600 * 1000 * 24 * 90);
                picker.$emit('pick', date);
            }
        }, 
        {
            text: '+365 zile',
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() + 3600 * 1000 * 24 * 365);
                picker.$emit('pick', date);
            }
        }]
    },

    GlobalDatePickerOptions_DoarViitor: {
        shortcuts: [{
            text: 'Azi',
            onClick(picker) {
                picker.$emit('pick', new Date());
            }
        },  
        {
            text: '+1 saptamana',
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
                picker.$emit('pick', date);
            }
        }, 
        {
            text: '+30 zile',
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
                picker.$emit('pick', date);
            }
        }, 
        {
            text: '+90 zile',
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() + 3600 * 1000 * 24 * 90);
                picker.$emit('pick', date);
            }
        }, 
        {
            text: '+365 zile',
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() + 3600 * 1000 * 24 * 365);
                picker.$emit('pick', date);
            }
        }]
    },

    set_token: function(newToken) {
        window.localStorage.setItem("gph-cloud-token", newToken);
        Vue.http.headers.common["Token"] = newToken;
    },

    get_token: function() {
        return window.localStorage.getItem("gph-cloud-token");
    },

    set_drepturi: function (drepturi) {
        window.localStorage.setItem('gph-cloud-drepturi_user', JSON.stringify(drepturi));
    },

    get_drepturi: function () {
        var drepturi = window.localStorage.getItem('gph-cloud-drepturi_user');
        return JSON.parse(drepturi);
    },

    set_user_type: function (user_type) {
        window.localStorage.setItem('gph-cloud-user_type', user_type);
    },

    get_user_type: function () {
        return window.localStorage.getItem('gph-cloud-user_type');
    },

    set_id_client_selectat: function (value) {
        window.localStorage.setItem('gph-cloud-id_client_selectat', value);
    },

    get_id_client_selectat: function () {
        return window.localStorage.getItem('gph-cloud-id_client_selectat');
    },

    is_client_selectat_activ(){
        var id_client_selectat      = this.get_id_client_selectat();
            
        if( id_client_selectat != null && id_client_selectat != ''  && id_client_selectat != '-1'){
            return true;
        }

        return false;
    },

    set_nume_client_selectat: function (value) {
        window.localStorage.setItem('gph-cloud-nume_client_selectat', value);
    },

    get_nume_client_selectat: function () {
        return window.localStorage.getItem('gph-cloud-nume_client_selectat');
    },

    is_logged_in: function() {
        var token = this.get_token();
        return token !== "";
    },

    logout: function() {
        this.set_token('');
        this.set_drepturi('');
    },

    verify_response: function(response) {
        if (response.body.NotLogged) {
            return false;
        }
        return true;
    },

    verify_login_and_redirect: function(vueInstance) {
        if (!this.is_logged_in()) {
            vueInstance.$router.push("/");
        }
    },
};
